import { Heading, Select, Grid } from '@hexa-ui/components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { GridItem, StyledCard } from '../../styles';
import { PatchUserMetadataTypes, Languages } from '../../../../services/user/userService';
import {
  useGetTimezoneOptions,
  TimezoneOptionsType
} from '../../../../hooks/useGetTimezoneOptions';

interface Props {
  availableLanguagesList: { abbreviation: string; name: string }[];
  // eslint-disable-next-line react/require-default-props
  userPreferences?: PatchUserMetadataTypes;
  handlePreferencesChange: (userPreference: PatchUserMetadataTypes) => void;
}

const PersonalPreferences = ({
  availableLanguagesList,
  userPreferences,
  handlePreferencesChange
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const timezones = useGetTimezoneOptions();

  return (
    <StyledCard border="medium" elevated="medium">
      <Heading size="H3" css={{ paddingBottom: '$6' }}>
        <FormattedMessage id="personalPreferences.title" />
      </Heading>
      <Grid.Container type="fluid" style={{ margin: 0, rowGap: '1.5rem' }}>
        <GridItem md={6} xs={12} css={{ '@desktopSmall': { paddingRight: '0.75rem' } }}>
          <Select.Root
            label={formatMessage({ id: 'personalPreferences.preferredLanguage' })}
            placeholder={formatMessage({ id: 'personalPreferences.selectLanguagePlaceholder' })}
            data-testid="language-selector"
            width="100%"
            value={userPreferences?.preferredLanguage}
            onChange={(value) => handlePreferencesChange({ preferredLanguage: value as Languages })}
          >
            {availableLanguagesList.map((lang) => (
              <Select.Option
                key={lang.abbreviation}
                value={lang.abbreviation}
                data-testid="listitem"
              >
                {lang.name}
              </Select.Option>
            ))}
          </Select.Root>
        </GridItem>
        <GridItem md={6} xs={12} css={{ '@desktopSmall': { paddingLeft: '0.75rem' } }}>
          <Select.Root
            label={formatMessage({ id: 'personalPreferences.timeFormat' })}
            placeholder={formatMessage({ id: 'personalPreferences.selectPlaceholder' })}
            data-testid="time-format-selector"
            width="100%"
            value={userPreferences?.preferredTimeFormat}
            onChange={(value) => handlePreferencesChange({ preferredTimeFormat: value })}
          >
            <Select.Option value="h:mm a">AM/PM - 2:00 PM</Select.Option>
            <Select.Option value="H:mm">24H - 14:00</Select.Option>
          </Select.Root>
        </GridItem>
        <GridItem xs={12}>
          <Select.Root
            label={formatMessage({ id: 'personalPreferences.yourTimezone' })}
            placeholder={formatMessage({ id: 'personalPreferences.selectPlaceholder' })}
            data-testid="timezone-selector"
            width="100%"
            value={userPreferences?.timezone}
            onChange={(value) => handlePreferencesChange({ timezone: value })}
          >
            {timezones.map(
              (tz: TimezoneOptionsType) => (
                <Select.Option key={tz.value} value={tz.value}>
                  {tz.text}
                </Select.Option>
              ),
              [timezones]
            )}
          </Select.Root>
        </GridItem>
      </Grid.Container>
    </StyledCard>
  );
};

export default PersonalPreferences;
