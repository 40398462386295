import MessageMap from '../i18n';

const ptBR: MessageMap = {
  test: {
    translate: 'Tradução'
  },
  userProfile: {
    title: 'Perfil do usuário'
  },
  userDetails: {
    title: 'Informações pessoais',
    firstName: 'Primeiro nome',
    lastName: 'Último nome',
    email: 'E-mail',
    emailTooltip: 'Seu e-mail é a chave para seu acesso e não pode ser alterado',
    edit: 'Editar'
  },
  securityDetails: {
    title: 'Senha',
    password: 'Senha',
    changePassword: 'Mudar a senha'
  },
  personalPreferences: {
    title: 'Preferências pessoais',
    preferredLanguage: 'Idioma de preferência',
    selectLanguagePlaceholder: 'Selecione um idioma',
    selectPlaceholder: 'Selecionar opção',
    timeFormat: 'Formato de hora',
    yourTimezone: 'Fuso horário'
  },
  notification: {
    isFederated: {
      error:
        'Você não pode alterar essas informações aqui. Entre em contato com sua equipe local de suporte de TI.'
    }
  }
};

export default ptBR;
