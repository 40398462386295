import MessageMap from '../i18n';

const idID: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Just English option available'
  },
  userProfile: {
    title: 'Profil Pengguna'
  },
  userDetails: {
    title: 'Informasi pribadi',
    firstName: 'Nama depan',
    lastName: 'Nama belakang',
    email: 'Email',
    emailTooltip: 'Email Anda adalah kunci akses Anda dan tidak dapat diubah',
    edit: 'Edit'
  },
  securityDetails: {
    title: 'Kata Sandi',
    password: 'Kata Sandi',
    changePassword: 'Ubah Kata Sandi'
  },
  personalPreferences: {
    title: 'Preferensi pribadi',
    preferredLanguage: 'Bahasa Pilihan',
    selectLanguagePlaceholder: 'Pilih Bahasa',
    selectPlaceholder: 'Pilih opsi',
    timeFormat: 'Format Waktu',
    yourTimezone: 'Zona waktu'
  },
  notification: {
    isFederated: {
      error: 'Anda tidak dapat mengubah informasi ini di sini. Hubungi Tim Dukungan TI lokal Anda.'
    }
  }
};

export default idID;
