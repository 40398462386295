/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import { getApiHost } from '../services/host/HostService';

export interface IRequest {
  url: string;
  body?: any;
}

const initialAxios = Axios.create();

const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

authentication.enhancedAxios(
  initialAxios as unknown as Parameters<typeof authentication.enhancedAxios>[0],
  {
    headers: [
      {
        country: userCountry || '',
        noorders: localStorage.getItem('noOrders') || ''
      }
    ]
  }
);

const Api = {
  post: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.post(`${getApiHost()}${url}`, body).catch((error) => ({
      hasError: true,
      ...error
    })),

  delete: ({ url }: IRequest): Promise<any> =>
    initialAxios.delete(`${getApiHost()}${url}`).catch((error) => ({ hasError: true, ...error })),

  get: ({ url }: IRequest): Promise<any> =>
    initialAxios.get(`${getApiHost()}${url}`).catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.put(`${getApiHost()}${url}`, body).catch((error) => ({
      hasError: true,
      ...error
    })),

  patch: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.patch(`${getApiHost()}${url}`, body).catch((error) => ({
      hasError: true,
      ...error
    }))
};

export default Api;
