import MessageMap from '../i18n';

const es419: MessageMap = {
  userProfile: {
    title: 'Perfil del usuario'
  },
  userDetails: {
    title: 'Información personal',
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    email: 'E-mail',
    emailTooltip: 'Su e-mail es la clave para su acceso y no see puede cambiar',
    edit: 'Editar'
  },
  securityDetails: {
    title: 'Contraseña',
    password: 'Contraseña',
    changePassword: 'Cambiar la contraseña'
  },
  personalPreferences: {
    title: 'Preferencias personales',
    preferredLanguage: 'Idioma preferido',
    selectLanguagePlaceholder: 'Selecciona un idioma',
    selectPlaceholder: 'Seleccionar opción',
    timeFormat: 'Formato del tiempo',
    yourTimezone: 'Zona horaria'
  },
  notification: {
    isFederated: {
      error:
        'No puede cambiar esta información aquí. Póngase en contacto con su equipo local de soporte de TI.'
    }
  }
};

export default es419;
