import { createGenerateClassName, StylesProvider, CssBaseline } from '@material-ui/core';
import React from 'react';
import IntlProvider from './i18n/provider';
import UserProfile from './pages/UserProfile/UserProfile';

function App(): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-user-profile-beta-mfe',
    seed: 'admin-portal-user-profile-beta-mfe'
  });

  return (
    <IntlProvider>
      <CssBaseline />
      <StylesProvider generateClassName={generateClassName}>
        <UserProfile />
      </StylesProvider>
    </IntlProvider>
  );
}
export default App;
