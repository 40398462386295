/* eslint-disable no-bitwise */
/* eslint-disable prefer-template */
import soft from 'timezone-soft';
import spacetime from 'spacetime';
import { useMemo } from 'react';
import { allTimezones } from '../../utils/TimezoneList';

type TimezoneOptionsType = {
  text: string;
  value: string;
};

const sortGMT = (aObject: TimezoneOptionsType, bObject: TimezoneOptionsType) => {
  const aValue = aObject.text;
  const bValue = bObject.text;
  const re = /^\(GMT([+-]\d{1,2}):(\d{1,2})\).*$/;
  const aOffset = parseFloat(aValue.replace(re, '$1.$2'));
  const bOffset = parseFloat(bValue.replace(re, '$1.$2'));
  // eslint-disable-next-line no-nested-ternary
  return aOffset < bOffset ? -1 : aOffset > bOffset ? 1 : 0;
};

const useGetTimezoneOptions = (): TimezoneOptionsType[] => {
  const memoizedTimezones = useMemo(() => {
    return Object.entries(allTimezones)
      .reduce<TimezoneOptionsType[]>((selectOptions, zone) => {
        const now = spacetime.now(zone[0]);
        const tz = now.timezone();
        const tzStrings = soft(zone[0]);

        const name = now.isDST() ? tzStrings[0].daylight?.name : tzStrings[0].standard?.name;

        const min = tz.current.offset * 60;
        const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
        const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;

        const timezone = `${prefix} ${name?.length && `(${name})`}`;

        selectOptions.push({ text: timezone, value: zone[0] });

        return selectOptions;
      }, [])
      .sort(sortGMT);
  }, []);

  return memoizedTimezones;
};

export { useGetTimezoneOptions };
export type { TimezoneOptionsType };
