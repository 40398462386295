import { UserInfo } from '__types__/User';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Info } from '@hexa-ui/icons';
import { Grid, Heading, Paragraph, TextButton, Tooltip } from '@hexa-ui/components';
import { StyledFlexContainer } from '../../../../components';
import { GridItem, StyledCard } from '../../styles';

interface Props {
  userData: UserInfo;
  handleEditClick: () => void;
}

const UserDetails = ({ userData, handleEditClick }: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <StyledCard border="medium" elevated="medium">
      <StyledFlexContainer justifycontent="space-between">
        <Heading size="H3" css={{ paddingBottom: '$6' }}>
          <FormattedMessage id="userDetails.title" />
        </Heading>
        {userData.isFederated ? (
          <Tooltip placement="top" text={formatMessage({ id: 'notification.isFederated.error' })}>
            <span>
              <TextButton
                data-testid="change-password-link"
                iconPosition="leading"
                size="medium"
                disabled
                css={{ pointerEvents: 'none' }}
              >
                <FormattedMessage id="userDetails.edit" />
              </TextButton>
            </span>
          </Tooltip>
        ) : (
          <TextButton
            onClick={handleEditClick}
            data-testid="change-password-link"
            iconPosition="leading"
            size="medium"
          >
            <FormattedMessage id="userDetails.edit" />
          </TextButton>
        )}
      </StyledFlexContainer>
      <Grid.Container type="fluid" style={{ margin: 0 }}>
        <GridItem xs={6}>
          <div>
            <Heading size="H5" css={{ paddingBottom: '$2' }}>
              <FormattedMessage id="userDetails.firstName" />
            </Heading>
            <Paragraph size="basis" css={{ paddingBottom: '$2' }}>
              {userData.firstName}
            </Paragraph>
          </div>
        </GridItem>
        <GridItem xs={6}>
          <div>
            <Heading size="H5" css={{ paddingBottom: '$2' }}>
              <FormattedMessage id="userDetails.lastName" />
            </Heading>
            <Paragraph size="basis" css={{ paddingBottom: '$2' }}>
              {userData.lastName}
            </Paragraph>
          </div>
        </GridItem>
      </Grid.Container>
      <div>
        <Heading size="H5" css={{ paddingBottom: '$2' }}>
          <StyledFlexContainer>
            <FormattedMessage id="userDetails.email" />
            <Tooltip placement="top" text={formatMessage({ id: 'userDetails.emailTooltip' })}>
              <Info size="medium" style={{ marginLeft: 5, color: '#939191' }} />
            </Tooltip>
          </StyledFlexContainer>
        </Heading>
        <Paragraph size="basis" css={{ paddingBottom: '$2' }}>
          {userData.email}
        </Paragraph>
      </div>
    </StyledCard>
  );
};

export default UserDetails;
