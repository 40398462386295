import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { useLogService } from 'admin-portal-shared-services';
import App from './App';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLogService().error(error);
    return <></>;
  }
});

export const { bootstrap, mount, unmount } = reactLifecycles;
