import MessageMap from '../i18n';

const frFR: MessageMap = {
  test: {
    translate: 'Traduire'
  },
  userProfile: {
    title: 'Profil utilisateur'
  },
  userDetails: {
    title: 'Informations personnelles',
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'E-mail',
    emailTooltip: 'Votre e-mail est la clé de votre accès et ne peut pas être modifié',
    edit: 'Modifier'
  },
  securityDetails: {
    title: 'Mot de passe',
    password: 'Mot de passe',
    changePassword: 'Modifier le mot de passe'
  },
  personalPreferences: {
    title: 'Préférences personnelles',
    preferredLanguage: 'Langue préférée',
    selectLanguagePlaceholder: 'Sélectionner une langue',
    selectPlaceholder: 'Sélectionner une option',
    timeFormat: 'Format de l’heure',
    yourTimezone: 'Fuseau horaire'
  },
  notification: {
    isFederated: {
      error:
        'Vous ne pouvez pas modifier ces informations ici. Contactez votre équipe d’assistance informatique locale.'
    }
  }
};

export default frFR;
