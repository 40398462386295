import MessageMap from '../i18n';

const koKR: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Just English option available'
  },
  userProfile: {
    title: '사용자 프로필'
  },
  userDetails: {
    title: '개인정보',
    firstName: '이름',
    lastName: '성',
    email: '이메일',
    emailTooltip: '이메일은 ID로 사용됩니다. 변경하실 수 없습니다.',
    edit: '수정'
  },
  securityDetails: {
    title: '비밀번호',
    password: '비밀번호',
    changePassword: '비밀번호 변경'
  },
  personalPreferences: {
    title: '개인 취향',
    preferredLanguage: '선호하는 언어',
    selectLanguagePlaceholder: '언어를 선택하세요',
    selectPlaceholder: '옵션 선택',
    timeFormat: '날짜 형식',
    yourTimezone: '시간대'
  },
  notification: {
    isFederated: {
      error: '정보를 변경할 수 없습니다. IT 지원팀에 문의하세요.'
    }
  }
};

export default koKR;
