import { styled } from '@material-ui/core';

interface StyledFlexContainerProps {
  alignitems?: 'center' | 'flex-start' | 'flex-end' | 'normal' | 'stretch';
  justifycontent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  flexdirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  cursor?: string;
}

export const StyledFlexContainer = styled('div')({
  display: 'flex',
  flexDirection: ({ flexdirection }: StyledFlexContainerProps) => flexdirection || 'row',
  alignItems: ({ alignitems }: StyledFlexContainerProps) => alignitems || 'center',
  justifyContent: ({ justifycontent }: StyledFlexContainerProps) => justifycontent || 'flex-start',
  cursor: ({ cursor }: StyledFlexContainerProps) => cursor
});
