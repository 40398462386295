import { Card, Divider, Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const StyledContainer = styled('div', {
  backgroundColor: '#fafafa',
  padding: '3.25rem'
});

export const PageTitle = styled('h1', {
  fontSize: '$8',
  fontFamily: 'Barlow',
  marginBottom: '$14'
});

export const StyledDivider = styled(Divider, {
  marginBottom: '$6'
});

export const StyledCard = styled(Card, {
  padding: '$8',
  overflow: 'initial'
});

export const GridItem = styled(Grid.Item, {
  display: 'block',
  padding: 0
});
