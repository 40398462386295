import { Heading, TextButton, Tooltip } from '@hexa-ui/components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserInfo } from '__types__/User';
import { StyledCard } from '../../styles';
import { StyledFlexContainer } from '../../../../components';
import { PasswordDots } from './styles';

interface Props {
  userData: UserInfo;
  handlePasswordEditClick: () => void;
}

const SecurityDetails = ({ userData, handlePasswordEditClick }: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <StyledCard border="medium" elevated="medium" css={{ height: '100%' }}>
      <StyledFlexContainer justifycontent="space-between">
        <Heading size="H3" css={{ paddingBottom: '$6', paddingRight: '$6' }}>
          <FormattedMessage id="securityDetails.title" />
        </Heading>
        {userData.isFederated ? (
          <Tooltip placement="top" text={formatMessage({ id: 'notification.isFederated.error' })}>
            <span>
              <TextButton
                data-testid="change-password-link"
                iconPosition="leading"
                size="medium"
                disabled
                css={{ pointerEvents: 'none' }}
              >
                <FormattedMessage id="securityDetails.changePassword" />
              </TextButton>
            </span>
          </Tooltip>
        ) : (
          <TextButton
            onClick={handlePasswordEditClick}
            data-testid="change-password-link"
            iconPosition="leading"
            size="medium"
          >
            <FormattedMessage id="securityDetails.changePassword" />
          </TextButton>
        )}
      </StyledFlexContainer>
      <div>
        <Heading size="H5" css={{ paddingBottom: '$2' }}>
          <FormattedMessage id="securityDetails.password" />
        </Heading>
        <Heading size="H5" css={{ paddingBottom: '$2' }}>
          <PasswordDots type="password" value="123456789" disabled />
        </Heading>
      </div>
    </StyledCard>
  );
};

export default SecurityDetails;
