import MessageMap from '../i18n';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Just English option available'
  },
  userProfile: {
    title: 'User profile'
  },
  userDetails: {
    title: 'Personal information',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    emailTooltip: 'Your email is the key for your access and can’t be changed',
    edit: 'Edit'
  },
  securityDetails: {
    title: 'Password',
    password: 'Password',
    changePassword: 'Change password'
  },
  personalPreferences: {
    title: 'Personal preferences',
    preferredLanguage: 'Preferred language',
    selectLanguagePlaceholder: 'Select a language',
    selectPlaceholder: 'Select option',
    timeFormat: 'Time format',
    yourTimezone: 'Timezone'
  },
  notification: {
    isFederated: {
      error: 'You can’t change this information here. Contact your local IT support team.'
    }
  }
};

export default enUS;
